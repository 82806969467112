@import "theme/theme.scss";

.application {
	padding: 7rem 0;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 767px) {
		padding: 3rem 0;
	}

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		border-left: 120px solid transparent;
		border-right: 120px solid transparent;
		border-bottom: 200px solid #d001d0;
		opacity: 0.8;
		transform: rotate(-25deg);
	}

	&:after {
		bottom: 40px;
		left: 30px;
		border-bottom: 200px solid $primary;
	}

	.title {
		color: #fff;
		text-transform: uppercase;
		text-align: center;
	}

	ul {
		position: relative;
		list-style: none;
		padding: 0;
		display: grid;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		max-width: 1024px;
		margin: 2em auto 0;
		z-index: 5;
	}
}
