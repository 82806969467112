// Colours
$primary: #fffc00;
$secondary: #000;
$alternative: #d001d0;

$dark-grey: #495057;
$light-grey: #c8c8c8;

// Fonts
$font-size-base: 12px;
$font-size-h1: $font-size-base * 5;
$font-size-h2: $font-size-base * 4;
$font-size-h3: $font-size-base * 3;
$font-size-h4: $font-size-base * 2;
$font-size-h5: $font-size-base * 1.5;
$font-size-h6: $font-size-base;
$font-size-p: $font-size-base * 1.5;

// Shadows

@mixin box-shadow {
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@mixin text-shadow {
	text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
