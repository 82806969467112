@import "../../../theme/theme.scss";

.upcoming_card_wrapper {
	position: absolute;
	left: 5%;
	bottom: 0;
	transform: translate(0, 50%);
	width: 90%;
	z-index: 10;
	display: flex;
	justify-content: center;

	.upcoming_card {
		@include box-shadow;
		background-color: #fff;
		padding: 1em 0;
		display: flex;
		border-radius: 4px;
		color: $dark-grey;
		width: 100%;
		max-width: 900px;

		@media screen and (max-width: 767px) {
			flex-direction: column;
			padding: 0;
		}

		.title_wrapper {
			padding: 0 2em;
			border-right: 1px solid $dark-grey;
			display: flex;
			justify-content: center;
			align-items: center;

			@media screen and (max-width: 767px) {
				padding: 1em;
				border: none;
				background-color: $alternative;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}

			h5 {
				font-weight: 300;
				color: $dark-grey;

				@media screen and (max-width: 767px) {
					color: #fff;
					font-weight: 800;
					text-transform: uppercase;
				}
			}
		}

		.courses_list {
			margin: 0;
			padding: 0 2em;
			list-style: none;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 2em;
			flex-grow: 1;

			@media screen and (max-width: 767px) {
				padding: 1em;
			}

			@media screen and (max-width: 567px) {
				grid-template-columns: auto;
				padding: 1em;
			}

			&__item {
				position: relative;

				.content_wrapper {
					.deadline {
						margin: 0.5em 0;
						&__title {
							font-weight: 800;
						}

						&_value {
							margin-top: 0;
						}
					}
				}

				&.booked,
				&.coming_soon {
					pointer-events: none;

					@media screen and (max-width: 576px) {
						display: none;
					}

					.content_wrapper {
						opacity: 0.2;
					}

					h6 {
						position: absolute;
						width: 70%;
						top: 50%;
						left: 50%;
						border-radius: 4px;
						transform: translate(-50%, -70%) rotate(-15deg);
						border: 2px solid $alternative;
						padding: 0.5em 0;
						text-align: center;
						background-color: rgba(255, 255, 255, 0.6);
						color: $alternative;
					}
				}

				p {
					font-size: $font-size-base;
				}

				button {
					padding: 0;
					color: $alternative;
					font-weight: 600;
					background-color: transparent;
					border: none;
					outline: none;
					cursor: pointer;
				}
			}
		}
	}
}
