@import "theme/theme.scss";

.progress {
	position: relative;
	height: 5px;
	background-color: $secondary;
	width: 70%;

	@media screen and (max-width: 1199px) {
		left: 0;
		width: 80%;
	}

	.bar {
		background-color: $alternative;
		height: 5px;
		transition: width 0.4s;
	}

	.breakpoints {
		list-style: none;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 50%;
		left: -7px;
		right: 7px;

		li {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			height: 16px;
			width: 16px;
			border-radius: 50%;
			background-color: $secondary;
			transition: 0.4s;
			font-size: 0.5em;
			font-weight: 800;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: none;

			&.active {
				background-color: $alternative;
			}
		}
	}
}
