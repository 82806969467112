@import "theme/theme.scss";

.cookiePolicy {
	background: #fff;
	padding: 2em;
	margin-top: 2em;
	position: relative;

	.close {
		position: absolute;
		top: 0;
		right: 0;
		height: 25px;
		width: 25px;
		border-radius: 50%;
		cursor: pointer;
		background-color: $alternative;
		transform: translate(50%, -50%);

		&::before {
			content: "";
			display: block;
			width: 60%;
			height: 3px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -80%) rotate(45deg);
		}

		&::after {
			content: "";
			display: block;
			width: 60%;
			height: 3px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -80%) rotate(-45deg);
		}
	}
}

.website_url,
.website_name {
	color: $alternative;
}
