@import "../../../theme/theme.scss";

.btn {
	position: relative;
	padding: 1em 2em;
	font-size: 1rem;
	font-weight: 800;
	text-transform: uppercase;
	border: none;
	border-radius: 4px;
	outline: none;
	cursor: pointer;
	transition: 0.2s;
	box-shadow: 3px 6px #000, 3px 6px 1px 1px $primary;

	&.compact {
		padding: 0.5em 1em;
	}

	&:hover {
		filter: saturate(2);
	}

	&:active {
		transform: translate(2px, 4px);
		box-shadow: 0 0 $secondary;
	}

	&.yellow {
		background-color: $primary;
	}

	&.black {
		background-color: $secondary;
		color: $primary;
		box-shadow: 3px 6px $primary, 3px 6px 1px 1px $secondary;

		&:active {
			box-shadow: 0 0 $primary;
		}
	}
}
