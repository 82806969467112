@import "../../theme/theme.scss";

.who_we_are_wrapper {
	background-color: #fff;
	padding: 10rem 0 7rem;

	h3 {
		text-transform: uppercase;
		text-align: center;
	}
	.content {
		display: grid;
		grid-template-columns: 6fr 4fr;
		grid-column-gap: 3em;

		@media screen and (max-width: 1199px) {
			grid-template-columns: 4fr 6fr;
			padding-top: 2em;
			align-items: center;
		}

		@media screen and (max-width: 767px) {
			grid-template-columns: 1fr;
		}

		.details {
			position: relative;
			padding: 3.5em 0 0;

			@media screen and (max-width: 1199px) {
				padding: 0;
			}

			@media screen and (max-width: 767px) {
				margin-top: -2em;
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				border-left: 120px solid transparent;
				border-right: 120px solid transparent;
				border-bottom: 200px solid $alternative;
				opacity: 0.2;
				transform: rotate(-25deg);
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				border-left: 90px solid transparent;
				border-right: 90px solid transparent;
				border-bottom: 150px solid $primary;
				opacity: 0.2;
				transform: rotate(-45deg);
			}

			p {
				position: relative;
				z-index: 10;
				margin-bottom: 1em;
				color: $dark-grey;
			}

			.contact_us {
				color: $alternative;
				border-bottom: 1px solid $alternative;
			}
		}
	}
}
