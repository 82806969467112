.popup_window {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	min-height: 100vh;
	background-color: rgba(255, 255, 255, 0.97);
	display: flex;
	justify-content: center;
	align-items: center;
}
