@import "theme/theme.scss";

.tuition {
	background-color: #fff;
	padding: 7rem 0 10rem;

	h3 {
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 1em;
	}

	.content {
		position: relative;
		z-index: 10;
		background-color: $secondary;
		color: #fff;
		display: grid;
		// grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 3em;
		max-width: 968px;
		padding: 1em;
		border-radius: 4px;

		@media screen and (max-width: 1199px) {
			padding: 2em;
		}

		@media screen and (max-width: 767px) {
			grid-template-columns: auto;
			padding: 2em 1em;
		}

		.price {
			padding: 2em 1em;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.prefix {
				display: flex;
				align-items: center;
				margin-right: 1em;
			}

			.early_container {
				display: flex;

				.regular {
					text-decoration: line-through;
					font-weight: 400;
					color: #f00;
				}

				.early_bird {
					text-transform: uppercase;
					color: $primary;
					position: relative;
					margin-left: 0.5em;

					span {
						position: absolute;
						top: 0;
						right: 0;
						transform: translate(150%, -15%);
						font-size: $font-size-h3;
					}
					
					.label {
						position: absolute;
						top: 0;
						right: 0;
						background-color: $alternative;
						color: #fff;
						font-size: $font-size-h6;
						line-height: 2em;
						padding: 0 1em;
						border-radius: 4px;
						text-transform: capitalize;
						transform: rotate(10deg) translate(50%, -120%);
					}
				}
			}

			.regular_container {
				display: flex;
			}

			 h2 {
				text-transform: uppercase;
				color: $primary;
				position: relative;
				span {
					position: absolute;
					top: 0;
					right: 0;
					transform: translate(150%, -15%);
					font-size: $font-size-h3;
				}
			}

			p {
				text-align: center;

				.early_bird_price {
					text-decoration: line-through;
				}
			}
		}

		.text {
			padding: 0 3em;

			@media screen and (max-width: 1199px) {
				padding: 3em 0;
			}

			@media screen and (max-width: 767px) {
				grid-template-columns: auto;
				padding-bottom: 0;
				padding-top: 1em;
			}

			h4 {
				margin-bottom: 1em;
			}

			p {
				margin-bottom: 1em;
				font-weight: 100;

				&:nth-child(4) {
					float: left;
					margin-right: 0.5em;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			.contact_us {
				color: $primary;
				border-bottom: 1px solid $primary;
			}
		}

		.reserve {
			padding: 1em 0 3em;
			display: flex;
			align-items: center;
			flex-direction: column;

			p {
				margin: 1em 0;
				text-transform: uppercase;
				font-weight: bold;
				text-align: center;
			}
		}
	}
}
