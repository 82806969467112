@import "theme/theme.scss";

.logos_wrapper {
	background-color: $primary;
	padding: 3em 0;

	h3 {
		text-align: center;
		text-transform: uppercase;
	}

	.logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 1em 0;

		img {
			padding-bottom: 1em;
			margin: 0 2em;
			width: 200px;
		}
	}
}
