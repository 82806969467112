.topics {
	padding: 0;
	margin: 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 3em;
	grid-row-gap: 3em;
	justify-content: space-between;

	@media screen and (max-width: 1199px) {
		grid-column-gap: 2em;
		grid-row-gap: 2em;
	}

	@media screen and (max-width: 767px) {
		grid-template-columns: auto;
	}
}
