@import "theme/theme.scss";

.financing {
	background-color: #ffffff;
	padding: 0 0 10rem;

	h3 {
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 1em;
		color: #fff;
	}

	.content_wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $secondary;
		padding: 7rem 0;

		.content {
			width: 95%;
			max-width: 1680px;
			margin: 0 auto;

			.modules {
				list-style: none;
				padding: 0;
				margin: 0;
				display: grid;
				justify-content: center;
				grid-template-columns: repeat(auto-fit, 320px);
				grid-gap: 0.5em;
				grid-template-rows: auto;
				grid-auto-rows: auto;

				@media screen and (max-width: 673px) {
					grid-template-columns: 1fr;
				}

				li {
					padding: 3em 2em;
					border: 1px solid $primary;
					background-color: $secondary;
					color: #fff;

					p {
						margin-top: 1.5em;

						@media screen and (max-width: 673px) {
							font-size: 1em;
						}
					}
					mark {
						margin: 0 0.25em;
					}
				}
			}

			.reserve {
				display: flex;
				justify-content: flex-end;
				padding: 2em 0;

				@media screen and (max-width: 673px) {
					justify-content: center;
				}
			}
		}
	}
}
