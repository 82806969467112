@import "theme/theme.scss";

.step {
	display: flex;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: 2em;
    width: 47%;
    min-width: 280px;
	border: 2px solid $primary;
	position: relative;

	.number {
		background-color: $primary;
		width: 50px;
		position: relative;

		p {
			position: absolute;
			width: 55px;
			height: 55px;
			border-radius: 50%;
			border: 10px solid $secondary;
			background-color: $primary;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: $font-size-h4;
			font-weight: 800;
			top: 50%;
			right: 0;
			transform: translate(50%, -50%);
		}
	}

	.content {
		padding: 1em 2.5em;
		background-color: $secondary;
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		color: #fff;

		.text {
			flex: 1 1 auto;
		}
		.icon {
			@media screen and (max-width: 767px) {
				display: none;
			}
			img {
				width: 80px;
			}
		}
	}
}
