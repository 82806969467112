@import "theme/theme.scss";

.subscribe_wrapper {
	display: flex;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(0, -50%);
	width: 100%;

	.subscribe {
		@include box-shadow;
		background-color: $primary;
		padding: 2em;
		border-radius: 4px;
		display: grid;
		grid-template-columns: auto 400px;
		grid-column-gap: 3em;
		align-items: center;

		@media screen and (max-width: 1199px) {
			grid-column-gap: 2em;
			width: 100%;
			border-radius: 0;
		}

		@media screen and (max-width: 767px) {
			grid-template-columns: auto;
			padding: 2em 1em;
		}

		h4 {
			font-weight: 800;

			@media screen and (max-width: 767px) {
				text-align: center;
				margin-bottom: 0.5em;
			}
		}


		h3 {
			@media screen and (max-width: 767px) {
				text-align: center;
			}
		}

		form {
			position: relative;

			input {
				border: none;
				padding: 0.75em 5.5em 0.75em 2em;
				border-radius: 21px;
				min-width: 400px;
				outline: none;

				@media screen and (max-width: 767px) {
					min-width: auto;
					width: 100%;
				}
			}

			button {
				@include box-shadow;
				position: absolute;
				right: 7px;
				top: 50%;
				transform: translate(0, -50%);
				border: none;
				background-color: $secondary;
				color: #fff;
				padding: 0.375em 1.5em;
				font-weight: 800;
				border-radius: 15px;
				outline: none;
				cursor: pointer;
			}

			button:disabled {
				opacity: 0.5;
			}
		}
	}
}