@import "theme/theme.scss";

.experience {
	padding: 7rem 0;
	display: flex;
	justify-content: space-evenly;

	@media screen and (max-width: 767px) {
		padding: 3rem 0;
		flex-direction: column;
	}

	.graphic {
		width: 50%;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 2em;
		}

		img {
			height: 80%;

			@media screen and (max-width: 767px) {
				height: 150px;
			}
		}
	}

	.content {
		width: 50%;
		max-width: 500px;
		padding: 0 1em;
		color: #fff;

		@media screen and (max-width: 767px) {
			width: 100%;
			padding: 0;
		}

		&__title {
			margin-bottom: 1em;
		}

		&__text {
			p {
				margin-bottom: 1em;
				font-weight: 100;
			}

			.contact_us {
				color: $primary;
				border-bottom: 1px solid $primary;
			}

			button {
				margin-top: 2em;
			}
		}
	}
}
