@import "./theme/theme.scss";

body {
	font-family: "Nunito Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $secondary;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	line-height: 1.5em;
}

h1,
h2,
h3 {
	font-weight: 800;
}

h1 {
	font-size: $font-size-h1;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-h1 * 0.9;
	}

	@media screen and (7) {
		font-size: $font-size-h1 * 0.7;
	}
}

h2 {
	font-size: $font-size-h2;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-h2 * 0.9;
	}

	@media screen and (max-width: 767px) {
		font-size: $font-size-h2 * 0.7;
	}
}

h3 {
	font-size: $font-size-h3;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-h3 * 0.9;
	}

	@media screen and (max-width: 767px) {
		font-size: $font-size-h3 * 0.7;
	}
}

h4 {
	font-size: $font-size-h4;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-h4 * 0.9;
	}

	@media screen and (max-width: 767px) {
		font-size: $font-size-h4 * 0.7;
	}
}

h5 {
	font-size: $font-size-h5;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-h5 * 0.9;
	}

	@media screen and (max-width: 767px) {
		font-size: $font-size-h5 * 0.7;
	}
}

h6 {
	font-size: $font-size-h6;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-h6 * 0.9;
	}

	@media screen and (max-width: 767px) {
		font-size: $font-size-h6 * 0.7;
	}
}

p {
	font-size: $font-size-p;

	@media screen and (max-width: 1199px) {
		font-size: $font-size-p * 0.9;
	}

	@media screen and (max-width: 767px) {
		font-size: $font-size-p * 0.7;
	}
}

a {
	text-decoration: none;
}

.container {
	width: 90%;
	max-width: 1140px;
	margin: 0 auto;
}

.App {
	overflow: hidden;
}

.pageScrollDisable {
	overflow: hidden;
}
