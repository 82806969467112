@import "theme/theme.scss";

.apply_container {
	@include box-shadow;
	width: 90%;
	height: 80vh;
	max-height: 500px;
	max-width: 900px;
	background-color: $primary;
	padding: 2em;
	display: flex;
	position: relative;

	.purchase_details {
		@include box-shadow;
		background-color: #fff;
		width: 100%;
		align-items: stretch;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 40%;
			left: 40%;
			border-left: 60px solid transparent;
			border-right: 60px solid transparent;
			border-bottom: 100px solid $alternative;
			opacity: 0.2;
			transform: rotate(25deg);
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			border-left: 90px solid transparent;
			border-right: 90px solid transparent;
			border-bottom: 150px solid $primary;
			opacity: 0.2;
			transform: rotate(-45deg);
		}

		.description {
			padding: 2em;
			display: grid;
			grid-template-columns: 1fr auto;
			grid-template-areas:
				"titleName titleFee"
				"nameValue feeValue";

			@media screen and (max-width: 767px) {
				grid-template-areas:
					"nameValue nameValue"
					"titleFee feeValue";
				align-items: center;
			}

			.header__title {
				grid-area: titleName;
				height: 3em;
				text-transform: uppercase;
				color: $dark-grey;
				display: flex;
				align-items: center;
				border-bottom: 1px dashed $dark-grey;

				@media screen and (max-width: 767px) {
					display: none;
				}
			}

			.header__price {
				grid-area: titleFee;
				min-height: 3em;
				text-transform: uppercase;
				color: $dark-grey;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				border-left: 1px dashed $dark-grey;
				border-bottom: 1px dashed $dark-grey;
				padding: 0 1em;

				@media screen and (max-width: 767px) {
					border: none;
				}
			}

			.body__course_description {
				grid-area: nameValue;
				padding: 1em 0;
				color: $dark-grey;

				@media screen and (max-width: 767px) {
					border-bottom: 1px dashed $dark-grey;
				}

				.course_details {
					list-style: none;
					padding: 0;
					margin: 0;
					color: $dark-grey;

					p {
						font-size: $font-size-base;
					}
				}
			}

			.body__price {
				grid-area: feeValue;
				text-align: center;
				padding: 1em 1em 0;
				border-left: 1px dashed $dark-grey;
				color: $dark-grey;

				@media screen and (max-width: 767px) {
					padding-top: 0;
					padding: 1em;
					border: none;
					color: $alternative;
				}
			}
		}
	}

	.purchase_form {
		padding: 1em 2em;
		display: flex;
		justify-content: flex-end;

		@media screen and (max-width: 767px) {
			justify-content: center;
			padding: 1em 0;
			margin: 0 auto;
		}

		&.full_screen {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #fff;
			display: block;
			overflow: scroll;

			@media screen and (max-width: 767px) {
				left: 5%;
				right: 5%;
			}
		}

		.submit {
			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
	}

	.paypalBtnsWrapper {
		position: relative;
	}
	.paypal {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		overflow: scroll;

		h4 {
			text-align: center;
			margin-top: 2em;
			margin-bottom: 1em;
		}
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		height: 25px;
		width: 25px;
		border-radius: 50%;
		cursor: pointer;
		background-color: $secondary;
		transform: translate(50%, -50%);

		&::before {
			content: "";
			display: block;
			width: 60%;
			height: 3px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			content: "";
			display: block;
			width: 60%;
			height: 3px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}
