@import "theme/theme.scss";

.sticky_bar {
	@include box-shadow();
	position: fixed;
	top: 50%;
	left: -20px;
	z-index: 100;
	list-style: none;
	padding: 0;
	margin: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (max-width: 768px) {
		display: none;
	}

	li {
		width: 60px;
		height: 40px;
		padding-left: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.3s;
		background-color: $secondary;

		&:first-child {
			border-top-right-radius: 4px;
		}

		&:last-child {
			border-bottom-right-radius: 4px;
		}

		img {
			width: 20px;
		}

		@media screen and (max-width: 768px) {
			width: 35px;
			height: 35px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;

			img {
				width: 20px;
			}
		}

		&:hover {
			background-color: $alternative;
			transform: translate(10px, 0);
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
}
