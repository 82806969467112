@import "theme/theme.scss";

footer {
	position: relative;
	padding: 7em 0 4em;

	.content {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: 1199px) {
			flex-direction: column;
		}

		.logo_wrapper {
			img {
				width: 300px;
			}
		}

		.links {
			display: flex;
			padding: 0;
			margin: 0;
			list-style: none;

			@media screen and (max-width: 1199px) {
				margin-bottom: 0.5em;
			}

			@media screen and (max-width: 767px) {
				flex-direction: column;
				align-items: center;
			}

			li {
				padding: 1em;

				@media screen and (max-width: 767px) {
					padding-bottom: 0;

					&:last-child {
						padding-bottom: 1em;
					}
				}

				a {
					color: #fff;

					&:hover {
						color: $primary;
					}
				}
			}
		}

		.socials {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			align-items: center;
			grid-column-gap: 1em;
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				a {
					img {
						transition: 0.2s;
						width: 25px;

						&:hover {
							transform: scale(1.2);
						}
					}
				}
			}
		}
	}
}
