@import "theme/theme.scss";

.learn {
	background-color: #fff;
	padding: 7rem 0;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 767px) {
		padding: 3rem 0;
	}

	.triangles {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		&__top {
			position: absolute;
			top: 100px;
			left: 10%;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				border-left: 220px solid transparent;
				border-right: 220px solid transparent;
				border-bottom: 400px solid $primary;
				opacity: 0.2;
				transform: rotate(-25deg);
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				border-left: 90px solid transparent;
				border-right: 90px solid transparent;
				border-bottom: 150px solid $alternative;
				opacity: 0.2;
				transform: rotate(-45deg);
			}
		}

		&__bottom {
			position: absolute;
			top: 550px;
			right: 10%;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				border-left: 220px solid transparent;
				border-right: 220px solid transparent;
				border-bottom: 400px solid $alternative;
				opacity: 0.2;
				transform: rotate(-45deg);
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				left: -250px;
				border-left: 250px solid transparent;
				border-right: 250px solid transparent;
				border-bottom: 480px solid $primary;
				opacity: 0.2;
				transform: rotate(-45deg);
			}
		}
	}

	.content {
		position: relative;
		z-index: 5;

		.title {
			text-transform: uppercase;
			text-align: center;
		}

		.subtitle {
			max-width: 900px;
			margin: 1em auto 0;
			text-align: center;
			color: $dark-grey;
		}

		.course {
			margin-top: 3em;

			.modules {
				position: relative;
				padding: 0;
				margin: 0;
				list-style: none;
				display: flex;
				justify-content: center;

				@media screen and (max-width: 767px) {
					margin-bottom: 2em;
					flex-direction: column;
					align-items: center;
				}

				.module {
					padding: 0.5em;
					cursor: pointer;
					width: 20%;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					position: relative;
					z-index: 10;
					color: $secondary;
					transition: 0.4s;

					@media screen and (max-width: 1199px) {
						width: 25%;
					}

					@media screen and (max-width: 767px) {
						width: 80%;
					}

					&.active {
						color: #fff;
					}
				}

				.slider {
					position: absolute;
					width: 20%;
					top: 0;
					bottom: 0;
					left: 0;
					background-color: $secondary;
					color: #fff;
					border-radius: 2em;
					transition: 0.4s;
					will-change: transition;

					@media screen and (max-width: 1199px) {
						left: 0;
						width: 20%;
					}

					&.step-0 {
						transform: translate(0, 0);

						@media screen and (max-width: 767px) {
							left: 10%;
							width: 80%;
							height: 2em;
							transform: translate(0, 0);
						}
					}

					&.step-1 {
						transform: translate(100%, 0);

						@media screen and (max-width: 767px) {
							left: 10%;
							width: 80%;
							height: 2em;
							transform: translate(0, 2.15em);
						}
					}

					&.step-2 {
						transform: translate(200%, 0);

						@media screen and (max-width: 767px) {
							left: 10%;
							width: 80%;
							height: 2em;
							transform: translate(0, 4.3em);
						}
					}

					&.step-3 {
						transform: translate(300%, 0);

						@media screen and (max-width: 767px) {
							left: 10%;
							width: 80%;
							height: 2em;
							transform: translate(0, 6.45em);
						}
					}

					&.step-4 {
						transform: translate(400%, 0);

						@media screen and (max-width: 767px) {
							left: 10%;
							width: 80%;
							height: 2em;
							transform: translate(0, 8.60em);
						}
					}
				}
			}

			.timescale {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 5em 0;

				@media screen and (max-width: 767px) {
					display: none;
				}

				&__title {
					margin-bottom: 2em;
				}
			}
		}
	}
}
