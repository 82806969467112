.topic {
	display: flex;

	.icon_wrapper {
		padding: 0 2em;
		display: flex;
		align-items: center;

		img {
			width: 70px;
		}

		@media screen and (max-width: 1199px) {
			padding: 0 1em;

			img {
				display: none;
			}
		}

		@media screen and (max-width: 767px) {
			padding: 0;
		}
	}

	.text {
		display: flex;
		flex-direction: column;

		h4 {
			margin-bottom: 1em;
		}
	}
}
