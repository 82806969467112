@import "theme/theme.scss";

.formWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    input {
      border: none;
      border-bottom: 1px solid $light-grey;
      background-color: none;
      margin-bottom: 1em;
      width: 90%;
      max-width: 300px;
      padding: 0.5em 0;
      outline: none;
      color: $alternative;

      &:focus {
        border-bottom: 1px solid $dark-grey;
      }
    }
  }

  .success_message {
    padding: 1em;
    text-align: center;
  }
}
