@import "../../theme/theme.scss";

@keyframes slideIn {
	0% {
		transform: translate(0, -100%) rotate(20deg);
	}
	60% {
		transform: translate(0, 0) rotate(20deg);
	}
	100% {
		transform: translate(0, -6%) rotate(20deg);
	}
}

.main_section {
	background: url("../../images/main_background.jpg") no-repeat center center;
	background-size: cover;
	min-height: 90vh;
	position: relative;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 767px) {
		min-height: 105vh;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
	}

	.logo_wrapper {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;
		padding: 1em;

		img {
			width: 250px;

			@media screen and (max-width: 767px) {
				width: 180px;
			}
		}
	}

	.triangles {
		position: relative;

		@media screen and (max-width: 767px) {
			display: none;
		}

		img {
			transform: translate(0, -100%) rotate(20deg);
			position: absolute;
			z-index: 10;

			@media screen and (max-width: 1199px) {
				width: 230px;
			}

			&:first-child {
				right: -50px;
				animation: slideIn 0.8s ease-out 1.4s forwards;
			}

			&:nth-child(2) {
				right: -80px;
				animation: slideIn 0.8s ease-out 1.1s forwards;
			}

			&:last-child {
				right: -110px;
				animation: slideIn 0.8s ease-out 0.8s forwards;
			}
		}
	}

	&__content {
		position: relative;
		z-index: 10;
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h1 {
			@include text-shadow;
			max-width: 800px;
			text-transform: uppercase;
			line-height: 1.2;
			color: #fff;
		}

		h4 {
			@include text-shadow;
			color: #fff;
			font-weight: 800;
			margin: 0.875em 0 1em;
		}
	}
}
