@import "theme/theme.scss";

.consent-message {
	position: fixed;
	bottom: 0;
	width: 100vw;
	min-height: 3em;
	background: $secondary;
	color: #fff;
	border-top: $primary solid 1px;
	padding: 2em;
	z-index: 10;
	display: grid;
	grid-template:
		"title button"
		"text button";

	h4 {
		grid-area: title;
		font-size: 1.2em;
	}

	p {
		grid-area: text;
		font-size: 0.9em;
	}

	.cta {
		grid-area: button;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}
